import React from 'react';
import Link from 'next/link';

import styles from './FooterBlippAdminInfo.module.scss';

const year = new Date().getFullYear();

export const FooterBlippAdminInfo: React.FC = () => (
  <>
    <div className={styles.cookiesAndPolicy}>
      <span>
        <Link href="/cookies">Cookies</Link>
      </span>
      <span>
        <a href="#" className="cookie-consent-settings">
          Inställningar för cookies
        </a>
      </span>
      <span>
        <Link href="/integritetspolicy">Integritetspolicy</Link>
      </span>
    </div>
    <div className={styles.orgNumberAndCopyright}>
      <span>Organisationsnummer: 556866-0681.</span>
      <span>Copyright © {year} Blipp AB.</span>
    </div>
  </>
);
