import React from 'react';
import Link from 'next/link';

import styles from './FooterNavList.module.scss';

type NavList = {
  text: string;
  href?: string | undefined;
};

export interface FooterNavListProps {
  heading: string;
  list: NavList[];
}

export const FooterNavList: React.FC<FooterNavListProps> = ({ heading, list }) => (
  <div className={styles.navList}>
    <h3 className={styles.heading}>{heading}</h3>
    <ul>
      {list.map((item) => (
        <li key={item.text}>
          <Link href={item.href || ''}>
            <a className={styles.link}>{item.text}</a>
          </Link>
        </li>
      ))}
    </ul>
  </div>
);
