import Link from 'next/link';
import React from 'react';

import { Icon, IconPropsType } from 'components/tokens/Icon';
import { Colors } from 'enums/Colors';
import { Direction } from 'enums/Direction';

import styles from './MetricCard.module.scss';

export enum MetricCardSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum MetricCardAlignment {
  Center = 'center',
  Left = 'left',
}

export interface MetricCardProps {
  icon: IconPropsType['Icon'];
  title: string;
  value: string;
  href?: string | undefined;
  size?: MetricCardSize;
  direction?: Direction;
  alignment?: MetricCardAlignment;
  backgroundColor?: Colors;
}

export const MetricCard: React.FC<MetricCardProps> = ({
  icon,
  title,
  value,
  href,
  size = MetricCardSize.Small,
  direction = Direction.Vertical,
  alignment = MetricCardAlignment.Left,
  backgroundColor = Colors.Transparent,
}) => (
  <div
    className={`${styles.metricCard} ${styles[size]} ${styles[direction]} ${styles[alignment]}`}
    style={{ backgroundColor: `var(--color-${backgroundColor})` }}
  >
    <div className={styles.icon}>
      <Icon icon={icon} />
    </div>
    <div className={styles.text}>
      <span className={styles.label}>{title}</span>
      {href ? (
        <Link href={href}>
          <a className={styles.value}>{value}</a>
        </Link>
      ) : (
        <span className={styles.value}>{value}</span>
      )}
    </div>
  </div>
);
