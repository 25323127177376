import React from 'react';
import Link from 'next/link';

import { Icon, IconType } from 'components/tokens/Icon';
import Trustpilot from 'public/assets/logotype/trustpilot.svg';
import { facebook, instagram, linkedin, tiktok, youtube } from 'icons/logo';

import styles from './FooterSocial.module.scss';

export const FooterSocial: React.FC = () => (
  <>
    <div className={styles.trustpilot}>
      <Link href="https://se.trustpilot.com/review/blipp.se">
        <a target="_blank">
          <Trustpilot className={styles.trustpilotLogotyp} />
          <p className={styles.rating}>
            <span className={styles.bigger}>4.7</span> / 5
          </p>
        </a>
      </Link>
    </div>
    <div className={styles.socialMediaIcons}>
      <Link href="https://www.instagram.com/blipp.se">
        <a target="_blank">
          <Icon icon={instagram} type={IconType.Fill} />
        </a>
      </Link>
      <Link href="http://facebook.com/blipp.se">
        <a target="_blank">
          <Icon icon={facebook} type={IconType.Fill} />
        </a>
      </Link>
      <Link href="https://www.tiktok.com/@blipp.se">
        <a target="_blank">
          <Icon icon={tiktok} type={IconType.Fill} />
        </a>
      </Link>
      <Link href="https://www.linkedin.com/company/blipp-se">
        <a target="_blank">
          <Icon icon={linkedin} type={IconType.Fill} />
        </a>
      </Link>
      <Link href="https://www.youtube.com/channel/UCIysDIalE84ML45oMAyGLMQ">
        <a target="_blank">
          <Icon icon={youtube} type={IconType.Fill} />
        </a>
      </Link>
    </div>
  </>
);
