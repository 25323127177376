import React from 'react';
import { useRouter } from 'next/router';

import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { Alert } from 'components/Alert';

export const Layout: React.FC = ({ children }) => {
  const router = useRouter();
  return (
    <div className="layout-wrapper">
      <Header isInverted />
      {false && router.pathname === '/foretag' ? (
        <Alert
          text="Re:lease - Just nu bjuder vi på uppläggningsavgiften"
          lightText="Under hela december bjuder vi på uppläggningsavgiften. Värde 695 kronor exkl. moms per bil."
          buttonText="Läs mer"
          link="/foretag/release"
        />
      ) : null}

      {children}
      <Footer />
    </div>
  );
};
