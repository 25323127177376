import React from 'react';

import Logotype from 'public/assets/logotype/logotype.svg';

import styles from './FooterBlippInfo.module.scss';

export const FooterBlippInfo: React.FC = () => (
  <>
    <Logotype className={styles.blippLogotype} />
    <p className={styles.address}>
      Blipp AB <br />
      Prinsgatan 1
      <br />
      541 31 Skövde
    </p>
  </>
);
